import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useHistory } from "react-router-dom";

import Loader from "../../../components/loader";
import SearchBar from "../../../components/searchBar";

import api from "../../../services/api";
import { APP_URL } from "../../../config";

import { dimensions, question_types } from "../../../constants";

import View from "../../../components/indicator";

export default ({ questionnaire }) => {
  const [indicators, setIndicators] = useState();
  const [filter, setFilter] = useState({ search: "" });
  const [view, setView] = useState();
  const [isGenerating, setIsGenerating] = useState(false);
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const { data: i } = await api.post(`/campaign_indicator/search`, {
        questionnaire_id: id,
        search: filter.search,
      });
      for (const indicator of i) {
        console.log(`${indicator.master_indicator_id} - ${indicator.question_type} - ${indicator.dimension} - ${indicator.key}`);
      }
      setIndicators(i);
    })();
  }, [filter]);

  const handleGenerate = async () => {
    try {
      setIsGenerating(true);
      toast.loading("Generating indicators... This may take a few minutes.");
      for (const indicator of indicators) {
        const { ok, data } = await api.put(`/campaign_indicator/generate/${indicator._id}`);
        if (!ok) throw new Error("Error!");
        setIndicators((indicators) => indicators.map((i) => (i._id === indicator._id ? data : i)));
      }
      toast.dismiss();
      toast.success("Generation completed successfully!");
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  if (!indicators) return <Loader />;

  return (
    <div className={`space-y-6 ${isGenerating ? "pointer-events-none opacity-50" : ""}`}>
      <View value={view} onChange={(e) => setView(null)} />

      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-bold w-full">{questionnaire.name} </h3>
        <div className="flex gap-2 justify-end">
          <button className="btn btn-primary whitespace-nowrap" onClick={() => handleGenerate()}>
            Generate random answer
          </button>
          <SearchBar search={filter.search} setFilter={setFilter} />
        </div>
      </div>
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ring-1 ring-primary-black-50">
              <table className="min-w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden table-auto">
                <thead className="bg-[#EFF2FE]">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Dimension
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Sub Dimension
                    </th>

                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Indicator
                    </th>

                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Question type
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-black-100">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary-black-50 bg-white">
                  {indicators.map((e) => {
                    return (
                      <tr key={`${e.indicator_id}-${e.key}`} className={`odd:bg-[#FCFCFC] hover:bg-black-10 cursor-pointer`} onClick={() => setView(e)}>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                          <p className={`text-center px-1 py-2 rounded font-medium ${dimensions.find((d) => d.name === e.dimension)?.color || "bg-gray-300"}`}>{e.dimension}</p>
                        </td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">{e.sub_dimension}</td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50 max-w-md">{e.question?.en}</td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50">
                          <div className="flex justify-start items-center">
                            <p
                              className={`w-max text-center px-4 py-2 rounded text-app-gray font-semibold ${
                                question_types.find((q_type) => q_type.name === e.question_type)?.color || "bg-gray-300"
                              }`}>
                              {e.question_type}
                            </p>
                          </div>
                        </td>
                        <td className="p-3 text-sm text-primary-black-90 border-r border-primary-black-50 max-w-md">{e.value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
