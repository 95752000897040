import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import SearchBar from "../../components/searchBar";
import Pagination from "../../components/pagination";
import SelectFund from "../../components/SelectFund";
import MultiSelect from "../../components/MultiSelect";
import Table from "../../components/Table";

import api from "../../services/api";

const tableHeaders = [{ title: "Name" }, { title: "Fund" }, { title: "Country" }, { title: "Currency" }, { title: "Sector" }, { title: "Created At" }];

export default () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const [companies, setCompanies] = useState();
  const [funds, setFunds] = useState();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
    per_page: 20,
    funds: urlParams.get("fund_id") ? [urlParams.get("fund_id")] : [],
  });

  async function getFunds() {
    const { data, ok } = await api.post("/fund/search");
    if (!ok) return toast.error("Error!");
    setFunds(data);
  }

  useEffect(() => {
    getFunds();
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      setLoading(true);
      const { data, total } = await api.post("/company/search", {
        search: filter.search,
        page: filter.page,
        per_page: filter.per_page,
        funds: filter.funds,
      });
      setCompanies(data);
      setTotal(total);
      setLoading(false);
    };
    fetchCompanies();
  }, [filter]);

  if (!companies || !funds) return <Loader />;

  return (
    <div className="space-y-6">
      {/* Filters Section */}
      <div className="bg-white rounded-lg border-b border-gray-200 py-4">
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between">
          <h1 className="text-xl font-semibold text-gray-900">Companies</h1>

          <div className="flex flex-wrap items-center gap-3">
            <div className="relative">
              <SearchBar
                search={filter.search}
                setFilter={setFilter}
                placeholder="Search companies..."
                className="w-full md:w-64 bg-gray-50 transition-colors focus-within:ring-2 focus-within:ring-primary-300"
              />
            </div>

            <div className="ml-2">
              <MultiSelect
                values={filter.funds?.map((fundId) => ({ value: fundId }))}
                onSelectedChange={(e) => {
                  const ids = e.map((e) => e.value);
                  setFilter({ ...filter, funds: ids });
                }}
                placeholder="Funds"
                options={funds.map((f) => ({ value: f._id, label: f.name }))}
                renderOption={(item) => <div>{item}</div>}
                className="w-56 bg-gray-50 hover:bg-gray-100 transition-colors"
              />
            </div>

            <div className="ml-2">
              <Create />
            </div>
          </div>
        </div>

        {/* Selected filters display */}
        {filter.funds.length > 0 && (
          <div className="mt-4 flex flex-wrap items-center gap-2">
            <span className="text-sm text-gray-500">Filters:</span>

            {filter.funds.map((fundId) => {
              const fundName = funds.find((f) => f._id === fundId)?.name || fundId;
              return (
                <div key={fundId} className="inline-flex items-center gap-1.5 px-2.5 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                  <span>{fundName}</span>
                  <button onClick={() => setFilter({ ...filter, funds: filter.funds.filter((f) => f !== fundId) })} className="text-gray-500 hover:text-gray-700">
                    ×
                  </button>
                </div>
              );
            })}

            {filter.funds.length > 0 && (
              <button onClick={() => setFilter({ ...filter, funds: [] })} className="text-sm text-gray-500 hover:text-gray-700 hover:underline">
                Clear all
              </button>
            )}
          </div>
        )}
      </div>

      {/* Table Section */}
      <div className="bg-white rounded-lg shadow-sm">
        <Table header={tableHeaders} total={total} className="rounded-lg overflow-hidden">
          {companies.map((e, index) => (
            <tr
              key={e._id}
              onClick={() => history.push(`/company/${e._id}`)}
              className={`group transition-colors hover:bg-primary/20 cursor-pointer ${index % 2 === 1 ? "bg-gray-50" : ""}`}>
              <td className="p-2 text-sm">
                <div className="font-medium text-gray-900">{e.name}</div>
              </td>
              <td className="p-2 text-sm text-gray-500">{e.fund_name || "-"}</td>
              <td className="p-2 text-sm text-gray-500">{e.country || "-"}</td>
              <td className="p-2 text-sm text-gray-500">{e.currency || "-"}</td>
              <td className="p-2 text-sm text-gray-500">{e.sector || "-"}</td>
              <td className="p-2 text-sm text-gray-500">{e.created_at ? e.created_at.slice(0, 10) : "-"}</td>
            </tr>
          ))}
        </Table>

        {/* Pagination Section */}
        <div className="border-t border-gray-200">
          <div className="flex items-center justify-between px-4 py-3">
            <div className="text-sm text-gray-500">{`${(filter.page - 1) * filter.per_page + 1} to ${Math.min(filter.page * filter.per_page, total)} of ${total} results`}</div>
            <Pagination
              per_page={filter.per_page}
              total={total}
              onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
              onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
              currentPage={filter.page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const history = useHistory();

  async function onCreate() {
    const res = await api.post("/company", values);
    toast.success("Created!");
    history.push(`/company/${res.data._id}`);
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Create Company
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Name</div>
            <input className="input block" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </div>
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Fund</div>
            <SelectFund
              value={values.fund_name}
              onChange={(fund) => {
                setValues({
                  ...values,
                  fund_id: fund._id,
                  fund_name: fund.name,
                });
              }}
            />
          </div>
        </div>

        <button className="btn btn-primary mt-4 disabled:opacity-80 disabled:cursor-not-allowed" disabled={!values.name || !values.fund_id || !values.fund_name} onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
