import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";

import toast from "react-hot-toast";
import { read, utils } from "xlsx";

import Loader from "../../../components/loader";
import Modal from "../../../components/modal";
import SearchBar from "../../../components/searchBar";
import Pagination from "../../../components/pagination";
import { dimensions } from "../../../constants";
import { question_types } from "../../../constants";
import api from "../../../services/api";

import View from "../../../components/indicator";
import Table from "../../../components/Table";

const tableHeaders = [
  {
    title: "Key",
    position: "center",
  },
  {
    title: "Dimension",
    position: "center",
  },
  {
    title: "Sub Dimension",
    position: "left",
  },
  {
    title: "Indicator",
    position: "left",
  },
  {
    title: "Question type",
    position: "left",
  },
  {
    title: "Regulation",
    position: "center",
  },
  {
    title: "Flag rule",
    position: "center",
  },
  {
    title: "NACE code",
    position: "center",
  },
];

export default ({ dataset }) => {
  const [indicators, setIndicators] = useState();
  const [filter, setFilter] = useState({ page: 1, per_page: 500 });
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [view, setView] = useState();
  const [isGenerating, setIsGenerating] = useState(false);
  const [sort, setSort] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const currentPage = filter.page;

  useEffect(() => {
    get();
  }, [filter]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  async function get() {
    const nf = { ...filter, dataset_id: dataset._id };
    const { data, total } = await api.post("/indicator/search", nf);
    setIndicators(data);
    setTotal(total);
  }

  const handleChange = () => {
    get();
  };

  const onSelectAll = () => {
    setSelectAll((state) => !state);
    if (!selectAll) {
      const newArr = [...selected];
      for (let i = 0; i < indicators.length; i++) {
        if (!newArr.find((e) => e._id === indicators[i]._id)) {
          newArr.push(indicators[i]);
        }
      }
      setSelected(newArr);
    } else {
      setSelected([]);
    }
  };

  function onSelect(v) {
    if (selectAll) setSelectAll(false);
    let arr = [...selected];
    if (arr.find((item) => item._id === v._id)) arr = arr.filter((item) => item._id !== v._id);
    else arr = [...selected, v];
    setSelected(arr);
  }

  const handleDeleteMultiple = async () => {
    for (let i = 0; i < selected.length; i++) {
      try {
        const indicator = selected[i];
        await api.remove(`/indicator/${indicator._id}`);
      } catch (e) {
        console.log(e);
      }
      toast.success("Done!");
    }
    get();
    setSelected([]);
  };

  const handleGenerate = async () => {
    try {
      setIsGenerating(true);
      toast.loading("Generating indicators... This may take a few minutes.");
      for (const indicator of indicators) {
        const { ok, data } = await api.put(`/indicator/generate/${indicator._id}`);
        setIndicators((indicators) => indicators.map((i) => (i._id === indicator._id ? data : i)));
        if (!ok) throw new Error("Error!");
      }
      toast.success("Generation completed successfully!");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsGenerating(false);
      toast.dismiss();
    }
  };

  const handleSort = (key) => {
    setSort((prevSort) => {
      const direction = prevSort[key] === 1 ? -1 : 1;
      return { [key]: direction };
    });

    setFilter((f) => ({ ...f, sort: { [key]: sort[key] === 1 ? -1 : 1 } }));
  };

  if (!indicators) return <Loader />;

  return (
    <div className={`space-y-6 ${isGenerating ? "pointer-events-none opacity-50" : ""}`}>
      <div className="flex items-center justify-between">
        <div className="flex gap-2 justify-between w-full">
          <div className="flex gap-2 w-96">
            <SearchBar search={filter.search} setFilter={setFilter} />
          </div>

          <div className="relative flex items-center gap-2" ref={dropdownRef}>
            <Create dataset={dataset} />
            <button className="border border-gray-300 h-[38px] p-2 rounded-md" onClick={() => setDropdownOpen(!dropdownOpen)}>
              <BsThreeDotsVertical className="text-lg text-gray-500" />
            </button>

            {dropdownOpen && (
              <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-lg z-10 border">
                <button
                  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    handleGenerate();
                    setDropdownOpen(false);
                  }}
                  disabled={isGenerating}>
                  {isGenerating ? "Generating..." : "Generate all pdf labels"}
                </button>

                <button
                  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    document.getElementById("file-upload").click();
                    setDropdownOpen(false);
                  }}>
                  Import Excel/CSV
                </button>

                {selected.length !== 0 && (
                  <button
                    className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                    onClick={() => {
                      handleDeleteMultiple();
                      setDropdownOpen(false);
                    }}>
                    Delete
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="hidden">
            <ImportExcel dataset={dataset} setIndicators={setIndicators} />
            <Create dataset={dataset} ref={(ref) => (window.createIndicatorRef = ref)} />
          </div>
        </div>
      </div>

      <View datasetId={dataset._id} value={view} handleChange={handleChange} onChange={(e) => setView(null)} />

      <div className="w-full overflow-hidden">
        <div className="overflow-x-auto">
          <Table header={tableHeaders} sort={sort} total={indicators.length} onSort={handleSort} loading={!indicators} className="w-full">
            {indicators.map((e) => {
              const checked = selected.some((s) => s._id === e._id);
              return (
                <tr key={e._id} className="hover:bg-gray-50 cursor-pointer border-b text-xs" onClick={() => setView(e)}>
                  <td
                    className="p-2 text-center"
                    onClick={(event) => {
                      if (dataset.type === "custom") {
                        event.stopPropagation();
                        onSelect(e);
                      }
                    }}>
                    <div className="flex items-center justify-center">
                      {dataset.type === "custom" && (
                        <label className={`cursor-pointer w-5 h-5 flex items-center justify-center rounded border mr-1 border-gray-300 ${checked && "bg-primary"}`}>
                          <AiOutlineCheck className="text-white text-xs" />
                        </label>
                      )}
                      <p>{e.key}</p>
                    </div>
                  </td>
                  <td className="p-2 text-center">
                    <p className={`text-center px-2 py-1 rounded font-medium ${dimensions.find((d) => d.name === e.dimension)?.color || "bg-gray-300"}`}>{e.dimension}</p>
                  </td>
                  <td className="p-2">
                    <div className="break-words">{e.sub_dimension}</div>
                  </td>
                  <td className="p-2">
                    <div className="break-words line-clamp-4">{e.question?.en}</div>
                  </td>
                  <td className="p-2">
                    <div className="flex justify-start items-center">
                      <p
                        className={`w-max text-center px-3 py-1 rounded text-app-gray font-semibold ${
                          question_types.find((q_type) => q_type.name === e.question_type)?.color || "bg-gray-300"
                        }`}>
                        {e.question_type}
                      </p>
                    </div>
                  </td>
                  <td className="p-2 text-center max-w-[100px] break-words">{e.regulation || "-"}</td>
                  <td className="p-2 text-center max-w-[100px] break-words">{e.flag_rule || "-"}</td>
                  <td className="p-2 text-center max-w-[100px] break-words">{e.nace_code || "-"}</td>
                </tr>
              );
            })}
          </Table>
        </div>
      </div>

      <div className="flex justify-between items-center p-3 bg-white rounded-lg border">
        <div className="text-sm">{` ${(currentPage - 1) * filter.per_page + 1} à ${Math.min(currentPage * filter.per_page, total)} sur ${total} résultat(s)`}</div>
        <Pagination
          per_page={filter.per_page}
          total={total}
          onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
          onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
          currentPage={filter.page}
        />
      </div>
    </div>
  );
};

const Create = (dataset) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    dataset_id: dataset.dataset._id,
    question_type: "text",
  });

  async function onCreate() {
    const { ok } = await api.post("/indicator", values);
    if (!ok) return toast.error("Error!");
    toast.success("Created!");
    window.location.reload();
    setOpen(false);
  }

  return (
    <div>
      <button className="btn btn-primary whitespace-nowrap" onClick={() => setOpen(true)}>
        Create indicator
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Name</div>
          <input className="input block" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>

        <button className="btn btn-primary disabled:opacity-80 disabled:cursor-not-allowed" disabled={!values.name} onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};

const ImportExcel = ({ dataset, setIndicators }) => {
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (event) => {
        const workbook = read(event.target.result, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const data = utils.sheet_to_json(worksheet);

        if (data.length === 0) {
          return toast.error("File is empty!");
        }

        let successCount = 0;
        let errorCount = 0;

        for (const row of data) {
          try {
            const response = await api.post("/indicator", {
              key: data.indexOf(row) + 1,
              tags:
                row.key
                  ?.split(/[;\n]/)
                  .map((tag) => tag.trim())
                  .filter(Boolean) || [],
              dimension: row.dimension,
              sub_dimension: row.sub_dimension,
              question: { en: row.question },
              help: { en: row.help },
              question_type: row.question_type && ["text", "number", "boolean"].includes(row.question_type) ? row.question_type : "text",
              question_unit: row.question_type === "number" ? row.question_unit : undefined,
              graph_title: row.graph_title,
              dataset_id: dataset._id,
            });

            setIndicators((indicators) => [...indicators, response.data]);

            if (!response.ok) {
              errorCount++;
              console.error(`Error creating indicator: ${response.error}`);
              continue;
            }

            successCount++;
          } catch (error) {
            errorCount++;
            console.error(`Error creating indicator:`, error);
          }
        }

        if (successCount > 0) {
          toast.success(`Successfully imported ${successCount} indicators`);
        }
        if (errorCount > 0) {
          toast.error(`Failed to import ${errorCount} indicators`);
        }
      };

      reader.onerror = () => {
        toast.error("Error reading file!");
      };

      reader.readAsBinaryString(file);
    } catch (error) {
      console.error(error);
      toast.error("Error importing file!");
    } finally {
      setLoading(false);
      e.target.value = "";
    }
  };

  return (
    <div>
      <button className="btn btn-primary whitespace-nowrap" onClick={() => document.getElementById("file-upload").click()} disabled={loading}>
        {loading ? "Importing..." : "Import Excel/CSV"}
      </button>
      <input id="file-upload" type="file" accept=".csv,.xlsx,.xls" className="hidden" onChange={handleFileUpload} />
    </div>
  );
};
