import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import SearchBar from "../../components/searchBar";
import Pagination from "../../components/pagination";
import Table from "../../components/Table";
import MultiSelect from "../../components/MultiSelect";

import api from "../../services/api";

const tableHeaders = [{ title: "Name" }, { title: "Region" }, { title: "Currency" }, { title: "Sector" }, { title: "Created At" }];

export default () => {
  const [funds, setFunds] = useState();
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
    per_page: 20,
    regions: [],
    sectors: [],
  });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const fetchFunds = async () => {
      setLoading(true);
      const { data, total } = await api.post("/fund/search", {
        search: filter.search,
        page: filter.page,
        per_page: filter.per_page,
        regions: filter.regions,
        sectors: filter.sectors,
      });
      setFunds(data);
      setTotal(total);
      setLoading(false);
    };

    fetchFunds();
  }, [filter]);

  if (!funds) return <Loader />;

  return (
    <div className="space-y-6">
      {/* Filters Section */}
      <div className="bg-white rounded-lg border-b border-gray-200 py-4">
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between">
          <h1 className="text-xl font-semibold text-gray-900">Funds</h1>

          <div className="flex flex-wrap items-center gap-3">
            <div className="relative">
              <SearchBar
                search={filter.search}
                setFilter={setFilter}
                placeholder="Search funds..."
                className="w-full md:w-64 bg-gray-50 transition-colors focus-within:ring-2 focus-within:ring-primary-300"
              />
            </div>

            <div className="ml-2">
              <Create />
            </div>
          </div>
        </div>

        {/* Selected filters display */}
        {(filter.regions.length > 0 || filter.sectors.length > 0) && (
          <div className="mt-4 flex flex-wrap items-center gap-2">
            <span className="text-sm text-gray-500">Filters:</span>

            {filter.regions.map((region) => (
              <div key={region} className="inline-flex items-center gap-1.5 px-2.5 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                <span>{region}</span>
                <button onClick={() => setFilter({ ...filter, regions: filter.regions.filter((r) => r !== region) })} className="text-gray-500 hover:text-gray-700">
                  ×
                </button>
              </div>
            ))}

            {filter.sectors.map((sector) => (
              <div key={sector} className="inline-flex items-center gap-1.5 px-2.5 py-1 bg-blue-50 text-blue-700 rounded-full text-sm">
                <span>{sector}</span>
                <button onClick={() => setFilter({ ...filter, sectors: filter.sectors.filter((s) => s !== sector) })} className="text-blue-500 hover:text-blue-700">
                  ×
                </button>
              </div>
            ))}

            {(filter.regions.length > 0 || filter.sectors.length > 0) && (
              <button onClick={() => setFilter({ ...filter, regions: [], sectors: [] })} className="text-sm text-gray-500 hover:text-gray-700 hover:underline">
                Clear all
              </button>
            )}
          </div>
        )}
      </div>

      {/* Table Section */}
      <div className="bg-white rounded-lg shadow-sm">
        <Table header={tableHeaders} total={total} className="rounded-lg overflow-hidden">
          {funds.map((fund, index) => (
            <tr
              key={fund._id}
              onClick={() => history.push(`/fund/${fund._id}`)}
              className={`group transition-colors hover:bg-primary/20 cursor-pointer ${index % 2 === 1 ? "bg-gray-50" : ""}`}>
              <td className="p-2 text-sm">
                <div className="font-medium text-gray-900">{fund.name}</div>
              </td>
              <td className="p-2 text-sm text-gray-500">{fund.region || "-"}</td>
              <td className="p-2 text-sm text-gray-500">{fund.currency || "-"}</td>
              <td className="p-2 text-sm text-gray-500">{fund.sector || "-"}</td>
              <td className="p-2 text-sm text-gray-500">{fund.created_at ? fund.created_at.slice(0, 10) : "-"}</td>
            </tr>
          ))}
        </Table>

        {/* Pagination Section */}
        <div className="border-t border-gray-200">
          <div className="flex items-center justify-between px-4 py-3">
            <div className="text-sm text-gray-500">{`${(filter.page - 1) * filter.per_page + 1} to ${Math.min(filter.page * filter.per_page, total)} of ${total} results`}</div>
            <Pagination
              per_page={filter.per_page}
              total={total}
              onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
              onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
              currentPage={filter.page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const history = useHistory();

  async function onCreate() {
    const res = await api.post("/fund", values);
    toast.success("Created!");
    history.push(`/fund/${res.data._id}`);
  }

  return (
    <div>
      <button className="btn btn-primary whitespace-nowrap" onClick={() => setOpen(true)}>
        Create fund
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Name</div>
          <input className="input block" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>

        <button className="btn btn-primary disabled:opacity-80 disabled:cursor-not-allowed" disabled={!values.name} onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
