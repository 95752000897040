import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import Information from "./information";
import Indicators from "./indicators";
import Template from "./template";

import api from "../../../services/api";

const INFORMATION = "INFORMATION";
const INDICATORS = "INDICATORS";
const RAW_DATA = "RAW_DATA";
const TEMPLATE = "TEMPLATE";

export default ({}) => {
  const [dataset, setDataset] = useState(null);
  const [tab, setTab] = useState(INDICATORS);

  const { id } = useParams();

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const { data } = await api.get(`/dataset/${id}`);
    setDataset(data);
  }

  if (!dataset) return <div />;

  return (
    <div className="flex flex-col">
      <div className="border-b border-gray-200">
        <h3 className="text-xl font-semibold mb-2">{dataset.name} </h3>
        <nav className="flex items-center">
          <button
            className={`px-4 py-3 text-sm font-medium transition-colors border-b-2 ${
              tab === INDICATORS ? "border-primary text-primary" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            onClick={() => setTab(INDICATORS)}>
            Indicators
          </button>
          {dataset.type === "custom" && (
            <button
              className={`px-4 py-3 text-sm font-medium transition-colors border-b-2 ${
                tab === TEMPLATE ? "border-primary text-primary" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
              onClick={() => setTab(TEMPLATE)}>
              Template - {dataset.master_dataset_name}
            </button>
          )}
          <button
            className={`px-4 py-3 text-sm font-medium transition-colors border-b-2 ${
              tab === INFORMATION ? "border-primary text-primary" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            onClick={() => setTab(INFORMATION)}>
            Infos
          </button>
          <button
            className={`px-4 py-3 text-sm font-medium transition-colors border-b-2 ${
              tab === RAW_DATA ? "border-primary text-primary" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            onClick={() => setTab(RAW_DATA)}>
            Raw
          </button>
        </nav>
      </div>
      <div className="mt-2">
        {tab === INFORMATION && <Information dataset={dataset} />}
        {tab === TEMPLATE && <Template dataset={dataset} />}
        {tab === INDICATORS && <Indicators dataset={dataset} />}
        {tab === RAW_DATA && <RawData dataset={dataset} />}
      </div>
    </div>
  );
};

const RawData = ({ dataset }) => {
  return (
    <div className="">
      <pre> {JSON.stringify(dataset, null, 2)}</pre>
    </div>
  );
};
