import React, { useEffect, useState } from "react";

import api from "../services/api";

export const SelectFund = ({ value, onChange }) => {
  const [funds, setFunds] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchFunds();
  }, []);

  async function fetchFunds() {
    try {
      setLoading(true);
      const { data, ok } = await api.post("/fund/search");
      if (!ok) return console.error("Failed to load funds");
      setFunds(data);
    } catch (error) {
      console.error("Error fetching funds:", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="relative">
      {loading ? (
        <div className="w-full px-4 py-2.5 bg-white border border-gray-200 rounded-lg text-gray-400">Loading funds...</div>
      ) : (
        <select
          className="w-full px-4 py-2.5 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary/30 focus:border-primary outline-none transition-all appearance-none cursor-pointer"
          value={value || ""}
          onChange={(e) => {
            e.preventDefault();
            const selectedFund = funds.find((fund) => fund.name === e.target.value);
            onChange(selectedFund);
          }}>
          <option value="" disabled={value ? false : true}>
            Select a fund
          </option>
          {funds
            .sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()))
            .map((fund) => (
              <option key={fund._id} value={fund.name}>
                {fund.name}
              </option>
            ))}
        </select>
      )}
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-500">
        <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </div>
    </div>
  );
};

export default SelectFund;
