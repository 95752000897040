import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import SearchBar from "../../components/searchBar";
import Pagination from "../../components/pagination";
import MultiSelect from "../../components/MultiSelect";
import Table from "../../components/Table";

import api from "../../services/api";

const tableHeaders = [{ title: "Fund" }, { title: "Company" }, { title: "Dataset" }, { title: "Campaign" }, { title: "Name" }, { title: "Created at" }];

export default () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const [questionnaires, setQuestionnaires] = useState();
  const [funds, setFunds] = useState();
  const [companies, setCompanies] = useState();
  const [campaigns, setCampaigns] = useState();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
    per_page: 20,
    funds: urlParams.get("fund_id") ? [urlParams.get("fund_id")] : [],
    companies: urlParams.get("company_id") ? [urlParams.get("company_id")] : [],
    campaigns: urlParams.get("campaign_id") ? [urlParams.get("campaign_id")] : [],
  });

  async function getCampaigns() {
    const { data, ok } = await api.post("/campaign/search");
    if (!ok) return toast.error("Error!");
    setCampaigns(data);
  }

  async function getCompanies() {
    const { data, ok } = await api.post("/company/search");
    if (!ok) return toast.error("Error!");
    setCompanies(data);
  }

  async function getFunds() {
    const { data, ok } = await api.post("/fund/search");
    if (!ok) return toast.error("Error!");
    setFunds(data);
  }

  useEffect(() => {
    getFunds();
    getCompanies();
    getCampaigns();
  }, []);

  useEffect(() => {
    getQuestionnaires();
  }, [filter]);

  async function getQuestionnaires() {
    setLoading(true);
    const { data, total } = await api.post("/questionnaire/search", {
      search: filter.search,
      page: filter.page,
      per_page: filter.per_page,
      funds: filter.funds,
      companies: filter.companies,
      campaigns: filter.campaigns,
    });
    setTotal(total);
    setQuestionnaires(data);
    setLoading(false);
  }

  if (!questionnaires || !funds || !companies || !campaigns) return <Loader />;

  return (
    <div className="space-y-6">
      {/* Filters Section */}
      <div className="bg-white rounded-lg border-b border-gray-200 py-4">
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between">
          <h1 className="text-xl font-semibold text-gray-900">Questionnaires</h1>

          <div className="flex flex-wrap items-center gap-3">
            <div className="relative">
              <SearchBar
                search={filter.search}
                setFilter={setFilter}
                placeholder="Search questionnaires..."
                className="w-full md:w-64 bg-gray-50 transition-colors focus-within:ring-2 focus-within:ring-primary-300"
              />
            </div>

            <div className="ml-2">
              <MultiSelect
                values={filter.funds?.map((fundId) => ({ value: fundId }))}
                onSelectedChange={(e) => {
                  const ids = e.map((e) => e.value);
                  setFilter({ ...filter, funds: ids });
                }}
                placeholder="Funds"
                options={funds.map((f) => ({ value: f._id, label: f.name }))}
                renderOption={(item) => <div>{item}</div>}
                className="w-56 bg-gray-50 hover:bg-gray-100 transition-colors"
              />
            </div>

            <div className="ml-2">
              <MultiSelect
                values={filter.companies?.map((companyId) => ({ value: companyId }))}
                onSelectedChange={(e) => {
                  const ids = e.map((e) => e.value);
                  setFilter({ ...filter, companies: ids });
                }}
                placeholder="Companies"
                options={companies.map((f) => ({ value: f._id, label: f.name }))}
                renderOption={(item) => <div>{item}</div>}
                className="w-56 bg-gray-50 hover:bg-gray-100 transition-colors"
              />
            </div>

            <div className="ml-2">
              <MultiSelect
                values={filter.campaigns?.map((campaignId) => ({ value: campaignId }))}
                onSelectedChange={(e) => {
                  const ids = e.map((e) => e.value);
                  setFilter({ ...filter, campaigns: ids });
                }}
                placeholder="Campaigns"
                options={campaigns.map((f) => ({ value: f._id, label: f.name }))}
                renderOption={(item) => <div>{item}</div>}
                className="w-56 bg-gray-50 hover:bg-gray-100 transition-colors"
              />
            </div>
          </div>
        </div>

        {/* Selected filters display */}
        {(filter.funds.length > 0 || filter.companies.length > 0 || filter.campaigns.length > 0) && (
          <div className="mt-4 flex flex-wrap items-center gap-2">
            <span className="text-sm text-gray-500">Filters:</span>

            {filter.funds.map((fundId) => {
              const fundName = funds.find((f) => f._id === fundId)?.name || fundId;
              return (
                <div key={fundId} className="inline-flex items-center gap-1.5 px-2.5 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                  <span>{fundName}</span>
                  <button onClick={() => setFilter({ ...filter, funds: filter.funds.filter((f) => f !== fundId) })} className="text-gray-500 hover:text-gray-700">
                    ×
                  </button>
                </div>
              );
            })}

            {filter.companies.map((companyId) => {
              const companyName = companies.find((c) => c._id === companyId)?.name || companyId;
              return (
                <div key={companyId} className="inline-flex items-center gap-1.5 px-2.5 py-1 bg-blue-50 text-blue-700 rounded-full text-sm">
                  <span>{companyName}</span>
                  <button onClick={() => setFilter({ ...filter, companies: filter.companies.filter((c) => c !== companyId) })} className="text-blue-500 hover:text-blue-700">
                    ×
                  </button>
                </div>
              );
            })}

            {filter.campaigns.map((campaignId) => {
              const campaignName = campaigns.find((c) => c._id === campaignId)?.name || campaignId;
              return (
                <div key={campaignId} className="inline-flex items-center gap-1.5 px-2.5 py-1 bg-green-50 text-green-700 rounded-full text-sm">
                  <span>{campaignName}</span>
                  <button onClick={() => setFilter({ ...filter, campaigns: filter.campaigns.filter((c) => c !== campaignId) })} className="text-green-500 hover:text-green-700">
                    ×
                  </button>
                </div>
              );
            })}

            {(filter.funds.length > 0 || filter.companies.length > 0 || filter.campaigns.length > 0) && (
              <button onClick={() => setFilter({ ...filter, funds: [], companies: [], campaigns: [] })} className="text-sm text-gray-500 hover:text-gray-700 hover:underline">
                Clear all
              </button>
            )}
          </div>
        )}
      </div>

      {/* Table Section */}
      <div className="bg-white rounded-lg shadow-sm">
        <Table header={tableHeaders} total={total} className="rounded-lg overflow-hidden">
          {questionnaires.map((e, index) => (
            <tr
              key={e._id}
              onClick={() => history.push(`/questionnaire/${e._id}`)}
              className={`group transition-colors hover:bg-primary/20 cursor-pointer ${index % 2 === 1 ? "bg-gray-50" : ""}`}>
              <td className="p-2 text-sm text-gray-500">{e.fund_name || "-"}</td>
              <td className="p-2 text-sm text-gray-500">{e.company_name || "-"}</td>
              <td className="p-2 text-sm text-gray-500">{e.dataset_name || "-"}</td>
              <td className="p-2 text-sm text-gray-500">{e.campaign_name || "-"}</td>
              <td className="p-2 text-sm">
                <div className="font-medium text-gray-900">{e.name || "-"}</div>
              </td>
              <td className="p-2 text-sm text-gray-500">{(e.created_at || "")?.slice(0, 10) || "-"}</td>
            </tr>
          ))}
        </Table>

        {/* Pagination Section */}
        <div className="border-t border-gray-200">
          <div className="flex items-center justify-between px-4 py-3">
            <div className="text-sm text-gray-500">{`${(filter.page - 1) * filter.per_page + 1} to ${Math.min(filter.page * filter.per_page, total)} of ${total} results`}</div>
            <Pagination
              per_page={filter.per_page}
              total={total}
              onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
              onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
              currentPage={filter.page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
