import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import SearchBar from "../../components/searchBar";
import Pagination from "../../components/pagination";
import SelectFund from "../../components/SelectFund";
import SelectCompany from "../../components/SelectCompany";
import SelectQuestionnaire from "../../components/SelectQuestionnaire";
import MultiSelect from "../../components/MultiSelect";
import Table from "../../components/Table";

import api from "../../services/api";

const tableHeaders = [{ title: "Name" }, { title: "Email" }, { title: "Fund" }, { title: "Role" }, { title: "Last login" }, { title: "Created at" }];

const List = () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const [users, setUsers] = useState();
  const [funds, setFunds] = useState();
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
    per_page: 50,
    funds: urlParams.get("fund_id") ? [urlParams.get("fund_id")] : [],
    roles: urlParams.get("role") ? [urlParams.get("role")] : [],
  });

  async function getFunds() {
    const { data, ok } = await api.post("/fund/search");
    if (!ok) return toast.error("Error!");
    setFunds(data);
  }

  useEffect(() => {
    getFunds();
  }, []);

  useEffect(() => {
    (async () => {
      const { data, total } = await api.post("/user/search", {
        roles: filter.roles,
        funds: filter.funds,
        search: filter.search,
        page: filter.page,
        per_page: filter.per_page,
      });
      setUsers(data);
      setTotal(total);
    })();
  }, [filter]);

  if (!users || !funds) return <Loader />;

  return (
    <div className="space-y-6">
      {/* Filters Section */}
      <div className="bg-white rounded-lg border-b border-gray-200 py-4">
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between">
          <h1 className="text-xl font-semibold text-gray-900">Users</h1>

          <div className="flex flex-wrap items-center gap-3">
            <div className="relative">
              <SearchBar
                search={filter.search}
                setFilter={setFilter}
                placeholder="Search users..."
                className="w-full md:w-64 bg-gray-50 transition-colors focus-within:ring-2 focus-within:ring-primary-300"
              />
            </div>

            <div className="flex flex-wrap items-center gap-2">
              <MultiSelect
                values={filter.roles?.map((role) => ({ value: role }))}
                onSelectedChange={(selectedOptions) => {
                  const selectedRoles = selectedOptions.map((option) => option.value);
                  setFilter({ ...filter, roles: selectedRoles });
                }}
                placeholder="Role"
                options={[
                  { value: "user", label: "User" },
                  { value: "partner", label: "Partner" },
                ]}
                className="w-56 bg-gray-50 hover:bg-gray-100 transition-colors"
              />

              <MultiSelect
                values={filter.funds?.map((fundId) => ({ value: fundId }))}
                onSelectedChange={(e) => {
                  const ids = e.map((e) => e.value);
                  setFilter({ ...filter, funds: ids });
                }}
                placeholder="Fund"
                options={funds.map((f) => ({ value: f._id, label: f.name }))}
                className="w-56 bg-gray-50 hover:bg-gray-100 transition-colors"
              />

              <div className="ml-2">
                <Create />
              </div>
            </div>
          </div>
        </div>

        {/* Selected filters display */}
        {(filter.roles.length > 0 || filter.funds.length > 0) && (
          <div className="mt-4 flex flex-wrap items-center gap-2">
            <span className="text-sm text-gray-500">Filters:</span>

            {filter.roles.map((role) => (
              <div key={role} className="inline-flex items-center gap-1.5 px-2.5 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                <span>{role.charAt(0).toUpperCase() + role.slice(1)}</span>
                <button onClick={() => setFilter({ ...filter, roles: filter.roles.filter((r) => r !== role) })} className="text-gray-500 hover:text-gray-700">
                  ×
                </button>
              </div>
            ))}

            {filter.funds.map((fundId) => {
              const fundName = funds.find((f) => f._id === fundId)?.name || fundId;
              return (
                <div key={fundId} className="inline-flex items-center gap-1.5 px-2.5 py-1 bg-blue-50 text-blue-700 rounded-full text-sm">
                  <span>{fundName}</span>
                  <button onClick={() => setFilter({ ...filter, funds: filter.funds.filter((f) => f !== fundId) })} className="text-blue-500 hover:text-blue-700">
                    ×
                  </button>
                </div>
              );
            })}

            {(filter.roles.length > 0 || filter.funds.length > 0) && (
              <button onClick={() => setFilter({ ...filter, roles: [], funds: [] })} className="text-sm text-gray-500 hover:text-gray-700 hover:underline">
                Clear all
              </button>
            )}
          </div>
        )}
      </div>

      {/* Table Section */}
      <div className="bg-white rounded-lg shadow-sm">
        <Table header={tableHeaders} total={total} className="rounded-lg overflow-hidden">
          {users.map((user, index) => (
            <tr
              key={user._id}
              onClick={() => history.push(`/user/${user._id}`)}
              className={`group transition-colors hover:bg-primary/20 cursor-pointer ${index % 2 === 1 ? "bg-gray-50" : ""}`}>
              <td className="p-2 text-sm">
                <div className="font-medium text-gray-900">{user.name}</div>
              </td>
              <td className="p-2 text-sm text-gray-500">{user.email}</td>
              <td className="p-2 text-sm text-gray-500">{user.fund_name}</td>
              <td className="p-2 text-sm">
                {user.role ? (
                  <span className={`px-2 py-1 rounded-lg text-xs font-medium ${user.role === "partner" ? "bg-blue-100 text-blue-700" : "bg-yellow-100 text-yellow-700"}`}>
                    {user.role}
                  </span>
                ) : (
                  <span className="px-2 py-1 rounded-lg text-xs font-medium bg-gray-100 text-gray-700">-</span>
                )}
              </td>
              <td className="p-2 text-sm text-gray-500">{user.last_login_at ? user.last_login_at.slice(0, 10) : "-"}</td>
              <td className="p-2 text-sm text-gray-500">{user.created_at ? user.created_at.slice(0, 10) : "-"}</td>
            </tr>
          ))}
        </Table>

        {/* Pagination Section */}
        <div className="border-t border-gray-200">
          <div className="flex items-center justify-between px-4 py-3">
            <div className="text-sm text-gray-500">{`${(filter.page - 1) * filter.per_page + 1} to ${Math.min(filter.page * filter.per_page, total)} of ${total} results`}</div>
            <Pagination
              per_page={filter.per_page}
              total={total}
              onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
              onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
              currentPage={filter.page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ companies: [], role: "user" });
  const history = useHistory();
  const [company, setCompany] = useState();
  const [questionnaire, setQuestionnaire] = useState();

  useEffect(() => {
    setCompany(undefined);
    setQuestionnaire(undefined);
    setValues((prevValues) => ({
      ...prevValues,
      questionnaire_name: "",
      questionnaire_id: "",
      companies: [],
    }));
  }, [values.role]);

  async function onCreate() {
    if (values.role === "user") values.companies = [{ id: company._id, name: company.name }];
    const { data, ok } = await api.post("/user/invite", values);
    if (questionnaire) {
      const users = [...questionnaire.users, data._id];
      await api.put(`/questionnaire/${questionnaire._id}`, { users });
    }
    if (!ok) return toast.error("Error!");
    toast.success("Created!");
    history.push(`/user/${data._id}`);
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Create user
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Name</div>
            <input className="input block" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </div>
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Email</div>
            <input className="input block" type="email" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
          </div>
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Role</div>

            <select
              onChange={(e) => setValues({ ...values, role: e.target.value })}
              value={values.role}
              className="select bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] cursor-pointer shadow-sm">
              <option disabled>Role</option>
              <option value="user">User</option>
              <option value="partner">Partner</option>
            </select>
          </div>
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Fund</div>
            <SelectFund
              value={values.fund_name}
              onChange={(fund) => {
                setValues({
                  ...values,
                  fund_id: fund._id,
                  fund_name: fund.name,
                });
              }}
            />
          </div>

          {values.role === "user" && (
            <>
              {" "}
              <div className="w-full">
                <div className="text-sm font-medium mb-2">Company</div>
                <SelectCompany
                  value={values.companies[0]?.name}
                  fund_id={values.fund_id}
                  onChange={(company) => {
                    setCompany(company);
                  }}
                />
              </div>
              <div className="w-full">
                <div className="text-sm font-medium mb-2">Questionnaire</div>
                <SelectQuestionnaire
                  value={questionnaire?.name}
                  onChange={(selectedQuestionnaire) => {
                    setQuestionnaire(selectedQuestionnaire);
                    setValues((prevValues) => ({
                      ...prevValues,
                      questionnaire_name: selectedQuestionnaire.name,
                      questionnaire_id: selectedQuestionnaire._id,
                    }));
                  }}
                  company_id={company?._id}
                />
              </div>
            </>
          )}
        </div>

        <button
          className="btn btn-primary mt-4 disabled:opacity-80 disabled:cursor-not-allowed"
          disabled={
            (values.role === "user" && (!values.questionnaire_id || !company)) || !values.name || !values.email || !values.fund_id
            // !values.questionnaire_id ||
            // !company
          }
          onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};

export default List;
