import React, { useState, useEffect, useRef } from "react";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { HiMagnifyingGlass } from "react-icons/hi2";

function MultiSelect({ id, options, width, values, onSelectedChange, placeholder = "Select an option", naceCode, className = "", renderOption }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (values) {
      setSelectedOptions(values);
    }
  }, [values]);

  function handleOptionClick(option) {
    let _selectedOptions;
    if (selectedOptions.find((_option) => _option.value === option.value)) {
      _selectedOptions = selectedOptions.filter((_option) => _option.value !== option.value);
    } else {
      _selectedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(_selectedOptions);
    onSelectedChange(_selectedOptions);
  }

  function handleToggleClick() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) setSearch("");
  }, [isOpen]);

  const filteredOptions = options.filter((o) => {
    if (!search) return true;
    return o.label.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div className={`relative ${width || ""} ${className}`}>
      <button
        id={id}
        type="button"
        onClick={handleToggleClick}
        className={`w-full flex justify-between items-center gap-2 px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500 transition-all duration-150`}>
        <span className="flex-1 truncate text-left">{selectedOptions.length === 0 ? placeholder : `${placeholder} (${selectedOptions.length})`}</span>
        <span className="text-gray-400">{isOpen ? <HiChevronUp className="h-5 w-5" /> : <HiChevronDown className="h-5 w-5" />}</span>
      </button>

      {isOpen && (
        <div ref={ref} className="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg border border-gray-200 flex flex-col" style={{ maxHeight: "280px" }}>
          <div className="p-2 border-b border-gray-200 flex-shrink-0">
            <div className="relative rounded-md flex items-center">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="block w-full h-10 pl-10 pr-3 border border-gray-300 rounded-md text-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 focus:border-gray-300"
                placeholder="Search options..."
              />
            </div>
          </div>

          <div className="overflow-y-auto flex-1">
            {filteredOptions.length === 0 ? (
              <div className="py-2 px-3 text-sm text-gray-500">No options found</div>
            ) : (
              <ul className="divide-y divide-gray-200">
                {filteredOptions.map((option, index) => {
                  const isSelected = selectedOptions.find((_option) => _option.value === option.value);

                  if (naceCode && option.value.length === 1) {
                    return (
                      <li key={index} className="px-3 py-2 bg-gray-50">
                        <h3 className="font-semibold text-sm text-gray-700">{option.label}</h3>
                      </li>
                    );
                  }

                  return (
                    <li key={index} onClick={() => handleOptionClick(option)} className="px-3 py-2 flex items-center gap-2 hover:bg-gray-50 cursor-pointer transition-colors">
                      {isSelected ? <MdCheckBox className="text-primary-500 h-5 w-5 flex-shrink-0" /> : <MdCheckBoxOutlineBlank className="text-gray-400 h-5 w-5 flex-shrink-0" />}
                      <span className={`text-sm ${isSelected ? "font-medium text-gray-900" : "text-gray-700"}`}>{renderOption ? renderOption(option.label) : option.label}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MultiSelect;

// import React from "react";
// import { IoClose } from "react-icons/io5";

// const MultiSelect = ({
//   values = [],
//   onSearch,
//   onChange,
//   placeholder,
//   options,
//   renderOption = (item) => <div>{item}</div>,
// }) => {
//   const [search, setSearch] = React.useState("");

//   React.useEffect(() => {
//     onSearch(search);
//   }, [search]);

//   return (
//     <div className="space-y-1 relative">
//       <input
//         className="rounded-xl p-1.5 px-4 border border-gray-300 bg-white placeholder:text-gray-300 !outline-none !ring-0"
//         value={search}
//         onChange={(e) => setSearch(e.target.value)}
//         placeholder={placeholder}
//       />
//       {search ? (
//         <div className="rounded bg-white divide-y divide-gray-200 border-[1px] border-gray-200 absolute z-20 top-full left-0 w-full max-h-80 overflow-y-auto">
//           {(options || [])
//             .filter((o) => !values.includes(o))
//             .map((option) => (
//               <div
//                 key={option}
//                 className="p-2 cursor-pointer hover:bg-gray-100"
//                 onClick={() => {
//                   onChange([...values, option]);
//                   setSearch("");
//                 }}
//               >
//                 {renderOption(option)}
//               </div>
//             ))}
//         </div>
//       ) : (
//         <div className="flex gap-1 items-center">
//           {values.map((value) => (
//             <div
//               key={value}
//               className="flex gap-1 pl-1 bg-primary-100 text-primary-600"
//             >
//               {value}
//               <div
//                 className="flex items-center justify-center bg-primary-100 text-primary-600 hover:bg-red-100 hover:text-red-600 px-2 cursor-pointer"
//                 onClick={() => onChange(values.filter((v) => v !== value))}
//               >
//                 <IoClose />
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default MultiSelect;
