import React, { useRef, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import api from "../../services/api";
import Autocomplete from "../Autocomplete";
import { NACE_CODE } from "../../constants";
import MultiSelect from "../MultiSelect";

export default ({ indicator, datasetId, onChange }) => {
  const [values, setValues] = useState(indicator);
  const [language, setLanguage] = useState("en");
  const [selectedNaceCodes, setSelectedNaceCodes] = useState([]);
  const initialSelectedNaceCodesRef = useRef(true);

  const onUpdate = async () => {
    const { data, ok } = await api.put(`/indicator/${indicator._id}`, values);
    if (!ok) return toast.error(data);
    setValues(data);
    onChange(data);
    toast.success("Updated!");
  };

  const onDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { data } = await api.remove(`/indicator/${indicator._id}`);
    toast.success("Delete!");
    window.location.reload();
  };

  async function fetchDimensions(dimensionFilter) {
    try {
      const nf = { dimension: dimensionFilter, dataset_id: datasetId };
      const { data } = await api.post("/indicator/search/dimensions", nf);
      return data;
    } catch (error) {
      console.error("Erreur lors de la récupération des dimensions:", error);
      return [];
    }
  }

  const handleChangeDimension = (e) => {
    setValues({ ...values, dimension: e });
  };

  const handleUpdateDimension = async (e) => {
    const { data, ok } = await api.put(`/indicator/${indicator._id}`, {
      dimension: e,
    });
    if (!ok) return toast.error(data);
    toast.success("Updated!");
  };

  useEffect(() => {
    if (values?.selected_nace_code && values?.selected_nace_code?.length > 0) {
      if (initialSelectedNaceCodesRef.current) {
        initialSelectedNaceCodesRef.current = false;
        const tempSelectedNaceCodes = values.selected_nace_code.map((codeString) => {
          const naceCode = NACE_CODE.find((item) => `${item.code} - ${item.name}` === codeString);
          return naceCode ? naceCode : null;
        });
        setSelectedNaceCodes(tempSelectedNaceCodes);
      }
    }
  }, [values?.selected_nace_code]);

  useEffect(() => {
    if (Array.isArray(selectedNaceCodes)) {
      const tempNaceCodes = selectedNaceCodes.map((naceCode) => `${naceCode.code} - ${naceCode.name}`);
      setValues({ ...values, selected_nace_code: tempNaceCodes });
    }
  }, [selectedNaceCodes]);

  const handleSelectedNaceCode = (e) => {
    const selectedNaceCodes = [];
    e.forEach((codeString) => {
      const section = codeString.value?.substring(0, 1);
      if (codeString.value?.length > 2) {
        const code = codeString.value?.substring(1);
        const naceCode = NACE_CODE.find((item) => item.section === section && item.code === code);

        if (naceCode) {
          selectedNaceCodes.push(naceCode);
        }
      } else {
        const naceCode = NACE_CODE.find((item) => item.section === section && item.code === "");

        if (naceCode) {
          selectedNaceCodes.push(naceCode);
        }
      }
    });
    setSelectedNaceCodes(selectedNaceCodes);
  };

  const handleGenerate = async () => {
    try {
      toast.loading("Generating indicator answer...");
      const { ok, data } = await api.put(`/campaign_indicator/generate/${values._id}`);
      if (!ok) throw new Error("Error!");
      setValues(data);
      toast.dismiss();
      toast.success("Indicator answer generated successfully!");
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-6 gap-4">
        <div className="flex flex-col col-span-2">
          <div className="text-sm font-medium mb-2">Dimension</div>
          <Autocomplete
            fetchData={fetchDimensions}
            handleUpdate={handleUpdateDimension}
            handleChange={handleChangeDimension}
            value={values?.dimension}
            placeholderText={"Indicate the dimension"}
          />
          {/* <input
            // i want it to take the whole width
            className="input w-full"
            value={values.dimension}
            onChange={(e) =>
              setValues({ ...values, dimension: e.target.value })
            }
            onBlur={onUpdate}
          /> */}
        </div>
        <div className="flex flex-col col-span-2">
          <div className="text-sm font-medium mb-2">Sub-dimension</div>
          <input className="input" value={values?.sub_dimension} onChange={(e) => setValues({ ...values, sub_dimension: e.target.value })} onBlur={onUpdate} />
        </div>
        <div className="flex flex-col col-span-2">
          <div className="text-sm font-medium mb-2">Key</div>
          <input className="input" value={values?.key} onChange={(e) => setValues({ ...values, key: e.target.value })} onBlur={onUpdate} />
        </div>

        <div className="flex flex-col col-span-1">
          <div className="text-sm font-medium mb-2">Language</div>
          <select className="select input" value={language} onChange={(e) => setLanguage(e.target.value)} onBlur={onUpdate}>
            <option value={"en"}>English</option>
            <option value={"fr"}>French</option>
          </select>
        </div>

        {(indicator.indicator_id || indicator.campaign_indicator_id) && (
          <div className="flex col-span-5 justify-end items-end">
            <button className="btn btn-primary whitespace-nowrap w-fit" onClick={() => handleGenerate()}>
              Generate random answer
            </button>
          </div>
        )}

        {/* <div className="w-full">
          <div className="text-sm font-medium mb-2">Regulation</div>
          <input
            className="input"
            value={values.regulation}
            onChange={(e) =>
              setValues({ ...values, regulation: e.target.value })
            }
          />
        </div> */}
      </div>

      <hr className="mt-10 mb-6" />
      <h1 className="text-2xl mb-10">Questionnaire</h1>

      <div className="grid grid-cols-6 gap-4">
        <div className="flex flex-col col-span-3">
          <div className="text-sm font-medium mb-2">NACE codes related to this criteria</div>
          <MultiSelect
            width="w-full"
            naceCode={true}
            values={selectedNaceCodes?.map((naceCode) => ({ value: `${naceCode.section}${naceCode.code}` }))}
            onSelectedChange={handleSelectedNaceCode}
            placeholder="Select..."
            options={NACE_CODE.map((nc) => ({ value: `${nc.section}${nc.code}`, label: `${nc.code} ${nc.name}` }))}
            renderOption={(item) => <div>{item}</div>}
          />
          <div className="flex items-center space-x-2 mt-4">
            <button className="btn btn-green" onClick={onUpdate}>
              Update Nace Code
            </button>
          </div>
        </div>
        <div className="flex flex-col col-span-3">
          <div className="text-sm font-medium mb-2">Question</div>
          <TextAreaQuestionnaire
            value={values?.question && values?.question[language]}
            onChange={(e) =>
              setValues({
                ...values,
                question: { ...values.question, [language]: e.target.value },
              })
            }
            onBlur={onUpdate}
          />
        </div>
        <div className="flex flex-col col-span-3 h-auto">
          <div className="text-sm font-medium mb-2">Help</div>
          <TextAreaQuestionnaire
            value={values?.help && values?.help[language]}
            onChange={(e) =>
              setValues({
                ...values,
                help: { ...values.help, [language]: e.target.value },
              })
            }
            onBlur={onUpdate}
          />
        </div>
        <div className="flex flex-col col-span-3">
          <div className="text-sm font-medium mb-2">Question type</div>
          <select className="select input" value={values?.question_type} onChange={(e) => setValues({ ...values, question_type: e.target.value })} onBlur={onUpdate}>
            <option value="text">Text</option>
            <option value="number">Number</option>
            <option value="boolean">Boolean</option>
            <option value="percentage">Percentage</option>
            <option value="multiple_choice">Multiple Choice</option>
          </select>
        </div>

        <div className="flex flex-col col-span-3">
          <div className="text-sm font-medium mb-2">Question unit</div>
          <input className="input" value={values?.question_unit} onChange={(e) => setValues({ ...values, question_unit: e.target.value })} onBlur={onUpdate} />
        </div>

        {["single_choice", "multiple_choice"].includes(values?.question_type) && (
          <QuestionChoices
            value={values?.question_choices}
            onChange={(question_choices) => {
              setValues({ ...values, question_choices });
            }}
            onBlur={onUpdate}
          />
        )}

        {["percentage"].includes(values?.question_type) && (
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Display type</div>
            <select className="select input" value={values?.display_type} onChange={(e) => setValues({ ...values, display_type: e.target.value })} onBlur={onUpdate}>
              <option value="number">Number</option>
              <option value="pie">Pie</option>
            </select>
          </div>
        )}
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <button className="btn btn-red" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};

const TextAreaQuestionnaire = ({ value, onChange, onBlur }) => {
  const TextAreaRef = useRef(null);

  useEffect(() => {
    TextAreaRef.current.style.height = "auto";
    TextAreaRef.current.style.height = TextAreaRef.current.scrollHeight + "px";
  }, [value]);

  return <textarea className="input focus:outline-none overflow-y-hidden" rows="1" ref={TextAreaRef} value={value} onChange={onChange} onBlur={onBlur} />;
};

const QuestionChoices = ({ value, onChange, onBlur }) => {
  const onAdd = () => {
    onChange([...value, { key: "", en: "", fr: "" }]);
  };

  const onLocalChange = (index, key, v) => {
    const newChoices = [...value];
    newChoices[index][key] = v;
    onChange(newChoices);
  };

  const onDelete = (index) => {
    const newChoices = [...value];
    newChoices.splice(index, 1);
    onChange(newChoices);
  };

  return (
    <div className="mt-4 col-span-3">
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium mb-2">Choices</div>
        <button className="btn btn-primary" onClick={onAdd}>
          Add
        </button>
      </div>
      <div className="flex flex-col">
        {value.length > 0 &&
          value.map((choice, index) => (
            <div className="w-full" key={index}>
              <div className="flex items-end justify-between">
                <div>
                  <div>EN</div>
                  <input className="input" value={choice.en} onChange={(e) => onLocalChange(index, "en", e.target.value)} />
                </div>
                <div>
                  <div>FR</div>
                  <input className="input" value={choice.fr} onChange={(e) => onLocalChange(index, "fr", e.target.value)} />
                </div>
                <button className="btn btn-red" onClick={() => onDelete(index)}>
                  Delete
                </button>
              </div>
            </div>
          ))}
      </div>

      <div className="flex items-center justify-end mt-4">
        <button className="btn btn-primary" onClick={onBlur}>
          Save Choices
        </button>
      </div>
    </div>
  );
};
