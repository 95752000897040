import React, { useState, useEffect } from "react";
import { Editor, EditorState, RichUtils, ContentState, convertToRaw, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "draft-js/dist/Draft.css";

const TextEditor = ({ title, onChange, reload, value, className, name = "default" }) => {
  return (
    <div>
      {title && <div className="text-base font-medium mb-2">{title}</div>}
      <DraftEditor value={value} onChange={onChange} name={name} className={className} />
    </div>
  );
};

const DraftEditor = ({ onChange, value, className, name = "default" }) => {
  const [editorState, setEditorState] = useState(() => {
    if (!value) return EditorState.createEmpty();
    const contentState = ContentState.createFromText(value);
    return EditorState.createWithContent(contentState);
  });

  useEffect(() => {
    if (value === undefined || value === null) return;
    const contentState = ContentState.createFromText(value);
    setEditorState(EditorState.createWithContent(contentState));
  }, [value]);

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
    if (onChange) {
      const text = newEditorState.getCurrentContent().getPlainText();
      onChange({ target: { value: text, name } });
    }
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  const toggleInlineStyle = (style) => {
    handleChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleBlockType = (blockType) => {
    handleChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  return (
    <div className={`w-full border rounded-lg ${className}`}>
      <div className="border-b p-2 flex gap-2 bg-gray-50">
        <button onClick={() => toggleInlineStyle("BOLD")} className="p-1 hover:bg-gray-200 rounded" type="button">
          B
        </button>
        <button onClick={() => toggleInlineStyle("ITALIC")} className="p-1 hover:bg-gray-200 rounded" type="button">
          I
        </button>
        <button onClick={() => toggleInlineStyle("UNDERLINE")} className="p-1 hover:bg-gray-200 rounded" type="button">
          U
        </button>
        <div className="border-r mx-2" />
        <button onClick={() => toggleBlockType("unordered-list-item")} className="p-1 hover:bg-gray-200 rounded" type="button">
          UL
        </button>
        <button onClick={() => toggleBlockType("ordered-list-item")} className="p-1 hover:bg-gray-200 rounded" type="button">
          OL
        </button>
      </div>
      <div className="p-4" style={{ minHeight: "300px" }}>
        <Editor editorState={editorState} onChange={handleChange} handleKeyCommand={handleKeyCommand} />
      </div>
    </div>
  );
};

export default TextEditor;
