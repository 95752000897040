import React from "react";
import { HiMagnifyingGlass, HiXMark } from "react-icons/hi2";

export const SearchBar = ({ search, setFilter, placeholder = "Search", className = "" }) => {
  const handleChange = (e) => {
    e.persist();
    setFilter((f) => ({ ...f, search: e.target.value, page: 1 }));
  };

  const clearSearch = () => {
    setFilter((f) => ({ ...f, search: "", page: 1 }));
  };

  return (
    <div className={`flex items-center w-full relative bg-white rounded-md border border-gray-300 h-[38px] ${className}`}>
      <div className="flex-shrink-0 pl-3">
        <HiMagnifyingGlass className="h-4 w-4 text-gray-400" aria-hidden="true" />
      </div>

      <input
        type="text"
        name="search"
        value={search}
        onChange={handleChange}
        className="w-full py-2 px-2 border-0 focus:ring-0 focus:outline-none text-sm text-gray-700 bg-transparent"
        placeholder={placeholder}
      />

      {search && (
        <button onClick={clearSearch} className="flex-shrink-0 pr-3">
          <HiXMark className="h-4 w-4 text-gray-400 hover:text-gray-600" />
        </button>
      )}
    </div>
  );
};

export default SearchBar;
