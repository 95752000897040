import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import Loader from "../../components/loader";

import api from "../../services/api";
import Modal from "../../components/modal";
import SearchBar from "../../components/searchBar";
import Pagination from "../../components/pagination";
import SelectFund from "../../components/SelectFund";
import MultiSelect from "../../components/MultiSelect";
import Table from "../../components/Table";

const tableHeaders = [{ title: "Name" }, { title: "Fund" }, { title: "Type" }, { title: "Master file" }, { title: "Created at" }];

export default () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const [datasets, setDatasets] = useState();
  const [funds, setFunds] = useState();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
    per_page: 20,
    funds: urlParams.get("fund_id") ? [urlParams.get("fund_id")] : [],
  });

  async function getFunds() {
    const { data, ok } = await api.post("/fund/search");
    if (!ok) return toast.error("Error!");
    setFunds(data);
  }

  useEffect(() => {
    getFunds();
  }, []);

  useEffect(() => {
    const fetchDatasets = async () => {
      setLoading(true);
      const { data, total } = await api.post("/dataset/search", {
        search: filter.search,
        per_page: filter.per_page,
        page: filter.page,
        funds: filter.funds,
      });
      setDatasets(data);
      setTotal(total);
      setLoading(false);
    };
    fetchDatasets();
  }, [filter]);

  if (!datasets || !funds) return <Loader />;

  return (
    <div className="space-y-6">
      {/* Filters Section */}
      <div className="bg-white rounded-lg border-b border-gray-200 py-4">
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between">
          <h1 className="text-xl font-semibold text-gray-900">Datasets</h1>

          <div className="flex flex-wrap items-center gap-3">
            <div className="relative">
              <SearchBar
                search={filter.search}
                setFilter={setFilter}
                placeholder="Search datasets..."
                className="w-full md:w-64 bg-gray-50 transition-colors focus-within:ring-2 focus-within:ring-primary-300"
              />
            </div>

            <div className="ml-2">
              <MultiSelect
                values={filter.funds?.map((fundId) => ({ value: fundId }))}
                onSelectedChange={(e) => {
                  const ids = e.map((e) => e.value);
                  setFilter({ ...filter, funds: ids });
                }}
                placeholder="Funds"
                options={funds.map((f) => ({ value: f._id, label: f.name }))}
                renderOption={(item) => <div>{item}</div>}
                className="w-56 bg-gray-50 hover:bg-gray-100 transition-colors"
              />
            </div>

            <div className="ml-2">
              <CreateDataset />
            </div>
          </div>
        </div>

        {/* Selected filters display */}
        {filter.funds.length > 0 && (
          <div className="mt-4 flex flex-wrap items-center gap-2">
            <span className="text-sm text-gray-500">Filters:</span>

            {filter.funds.map((fundId) => {
              const fundName = funds.find((f) => f._id === fundId)?.name || fundId;
              return (
                <div key={fundId} className="inline-flex items-center gap-1.5 px-2.5 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                  <span>{fundName}</span>
                  <button onClick={() => setFilter({ ...filter, funds: filter.funds.filter((f) => f !== fundId) })} className="text-gray-500 hover:text-gray-700">
                    ×
                  </button>
                </div>
              );
            })}

            {filter.funds.length > 0 && (
              <button onClick={() => setFilter({ ...filter, funds: [] })} className="text-sm text-gray-500 hover:text-gray-700 hover:underline">
                Clear all
              </button>
            )}
          </div>
        )}
      </div>

      {/* Table Section */}
      <div className="bg-white rounded-lg shadow-sm">
        <Table header={tableHeaders} total={total} className="rounded-lg overflow-hidden">
          {datasets.map((e, index) => (
            <tr
              key={e._id}
              onClick={() => history.push(`/dataset/${e._id}`)}
              className={`group transition-colors hover:bg-primary/20 cursor-pointer ${index % 2 === 1 ? "bg-gray-50" : ""}`}>
              <td className="p-2 text-sm">
                <div className="font-medium text-gray-900">{e.name}</div>
              </td>
              <td className="p-2 text-sm text-gray-500">{e.fund_name || "-"}</td>
              <td className="p-2 text-sm">
                {e.type ? (
                  <span className={`px-2 py-1 rounded-lg text-xs font-medium ${e.type === "master" ? "bg-blue-100 text-blue-700" : "bg-yellow-100 text-yellow-700"}`}>
                    {e.type}
                  </span>
                ) : (
                  <span className="px-2 py-1 rounded-lg text-xs font-medium bg-gray-100 text-gray-700">-</span>
                )}
              </td>
              <td className="p-2 text-sm text-gray-500">{e.master_dataset_name || "-"}</td>
              <td className="p-2 text-sm text-gray-500">{e.created_at ? dayjs(e.created_at).format("DD/MM/YYYY") : "-"}</td>
            </tr>
          ))}
        </Table>

        {/* Pagination Section */}
        <div className="border-t border-gray-200">
          <div className="flex items-center justify-between px-4 py-3">
            <div className="text-sm text-gray-500">{`${(filter.page - 1) * filter.per_page + 1} to ${Math.min(filter.page * filter.per_page, total)} of ${total} results`}</div>
            <Pagination
              per_page={filter.per_page}
              total={total}
              onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
              onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
              currentPage={filter.page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CreateDataset = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ type: "master" });
  const history = useHistory();

  async function onCreate() {
    const res = await api.post("/dataset", values);
    toast.success("Created!");
    history.push(`/dataset/${res.data._id}`);
  }

  const isValid = () => {
    if (!values.name) return false;
    if (values.type === "custom" && !values.master_dataset_id) return false;
    return true;
  };

  return (
    <div>
      <button className="px-4 py-2 bg-primary text-white rounded-lg font-medium transition-all duration-200 flex items-center gap-2" onClick={() => setOpen(true)}>
        <span>New Dataset</span>
      </button>

      <Modal isOpen={open} className="max-w-2xl w-full p-0 rounded-xl overflow-hidden shadow-xl" onClose={() => setOpen(false)}>
        <div className="bg-primary/5 px-8 py-6 border-b border-gray-100">
          <h2 className="text-xl font-semibold text-gray-900">Create New Dataset</h2>
          <p className="text-gray-500 mt-1">
            Configure your dataset details below. A Master dataset is a primary source file, while a Custom dataset is derived from an existing Master dataset with specific
            modifications.
          </p>
        </div>

        <div className="p-8 space-y-6">
          <div>
            <div className="text-sm font-semibold text-gray-700 mb-3">Dataset Type</div>
            <div className="flex items-center">
              <div className="relative inline-flex h-9 bg-gray-100 rounded-full p-1 shadow-inner">
                <button
                  onClick={() => setValues({ ...values, type: "master" })}
                  className={`relative flex items-center justify-center px-5 py-1.5 text-sm font-medium rounded-full transition-all duration-200 ${
                    values.type === "master" ? "bg-primary text-white shadow-sm" : "text-gray-600 hover:text-gray-800"
                  }`}>
                  Master File
                </button>
                <button
                  onClick={() => setValues({ ...values, type: "custom" })}
                  className={`relative flex items-center justify-center px-5 py-1.5 text-sm font-medium rounded-full transition-all duration-200 ${
                    values.type === "custom" ? "bg-primary text-white shadow-sm" : "text-gray-600 hover:text-gray-800"
                  }`}>
                  Custom File
                </button>
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="dataset-name">
              Name
            </label>
            <input
              id="dataset-name"
              className="w-full px-4 py-2.5 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary/30 focus:border-primary outline-none transition-all"
              placeholder="Enter dataset name"
              value={values.name || ""}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
          </div>

          {values.type === "custom" && (
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">Master Dataset</label>
                <DatasetSelect
                  value={values.master_dataset_name}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      master_dataset_name: e.name,
                      master_dataset_id: e._id,
                    })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">Fund</label>
                <SelectFund
                  value={values.fund_name}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      fund_name: e.name,
                      fund_id: e._id,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>

        <div className="bg-gray-50 px-8 py-4 flex justify-end gap-3 border-t border-gray-100">
          <button className="px-4 py-2 bg-white border border-gray-200 text-gray-700 rounded-lg hover:bg-gray-50 font-medium transition-colors" onClick={() => setOpen(false)}>
            Cancel
          </button>
          <button
            className="px-5 py-2 bg-primary text-white rounded-lg font-medium shadow-sm hover:shadow-md transition-all disabled:bg-primary/70 disabled:shadow-none disabled:cursor-not-allowed"
            disabled={!isValid()}
            onClick={onCreate}>
            Create Dataset
          </button>
        </div>
      </Modal>
    </div>
  );
};

const DatasetSelect = ({ value, onChange }) => {
  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDatasets();
  }, []);

  async function fetchDatasets() {
    try {
      setLoading(true);
      const { data, ok } = await api.post("/dataset/search", { type: "master" });
      if (!ok) return toast.error("Failed to load datasets");
      setDatasets(data);
    } catch (error) {
      console.error("Error fetching datasets:", error);
      toast.error("Could not load datasets");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="relative">
      {loading ? (
        <div className="w-full px-4 py-2.5 bg-white border border-gray-200 rounded-lg text-gray-400">Loading datasets...</div>
      ) : (
        <select
          className="w-full px-4 py-2.5 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary/30 focus:border-primary outline-none transition-all appearance-none cursor-pointer"
          value={value || ""}
          onChange={(e) => {
            e.preventDefault();
            const selectedDataset = datasets.find((dataset) => dataset.name === e.target.value);
            onChange(selectedDataset);
          }}>
          <option value="" disabled={value ? false : true}>
            Select a dataset
          </option>
          {datasets
            .sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()))
            .map((dataset) => (
              <option key={dataset._id} value={dataset.name}>
                {dataset.name}
              </option>
            ))}
        </select>
      )}
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-500">
        <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </div>
    </div>
  );
};
